import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahBasketballImage from '../../assets/images/publication-banners/Utah-Basketball.jpg';

const UtahBasketball = (props) => (
  <Layout>
    <Helmet>
      <title>University of Utah Basketball - Mills Publishing Inc.</title>
    </Helmet>
    <meta
      name="description"
      content="Advertising and event information for the Utah Basketball season game day programs. Please contact Mills Publishing for availability."
    />

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>University of Utah Basketball</h1>
          </header>
          <span className="image main">
            <img src={UtahBasketballImage} alt="" />
          </span>
          <p className="box">
            Distributed to attendees at every home game, the U of U basketball
            programs provide you with an opportunity to reach a passionate
            audience. The Utes never fail to impress—your ad, placed inside
            their program, will be exposed to the ultimate basketball crowd.
          </p>
          <p className="box">
            Excitement and optimism for a new era in Utah basketball are
            growing. Craig Smith will serve as the 16th head coach of the
            Runnin’ Utes. He has earned four conference coach of the year awards
            (2006, 2007, 2017, 2019) in three different conferences and was
            named the 2007 NAIA II National Coach of the Year.
            <br />
            <br />
            Utah will welcome another impressive slate of Pac-12 and
            non-conference opponents to the Huntsman Center this season. Over
            the last seven years, the Utes have a 108-23 home court record and
            are among the highest drawing teams in the Pac-12.
            <br />
            <br />
            This season, we will produce a Utah Basketball Yearbook which will
            include rosters, coaches bios and more. These yearbooks will be
            given free to fans and many people keep theirs as a timeless
            keepsake. Sign up today to advertise in the Utah Basketball
            Yearbook.
            <br />
            <br />
            The Runnin’ Utes will have approximately 15 home games this season,
            with an average attendance of 10,561 fans. The Yearbooks will be
            available for free at all home games. An online copy of the Yearbook
            is available on smartphones with access to fans and friends during
            the games with a QR code provided with the magazine. Anticipated
            readership, 60,000
          </p>
          <ul className="actions">
            <li>
              <a
                href="https://utahutes.com/sports/mens-basketball"
                className="button special"
              >
                <i className="icon fa-globe"> </i>University of Utah Basketball
                Website
              </a>
            </li>
          </ul>
          <h2>
            University of Utah Basketball {new Date().getFullYear()} Season
            Information
          </h2>
          New season information coming soon.
          <div className="grid-wrapper">
            <div className="col-6 box">
              <h3>TBD</h3>
              <p>
                <strong>TBD</strong> <br />
                TBD
                <br />
                <br />
                <h5>Advertising Information</h5>
                <section className="box">
                  File Ready Deadline: TBD <br />
                  Circulation: TBD
                </section>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahBasketball;
